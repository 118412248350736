import './App.css';

const App = () => {
  console.log('Oh hi, welcome to the dark side 🌚');
  return (
    <div className="everything">
      <h1>Hi!</h1>
      <p>I am Eirini and I like to code stuff in JavaScript.</p>
      <p>
        Feel free to get in touch:{' '}
        <a href="mailto:eirini.pappa.dev@gmail.com" className="email-link">
          eirini.pappa.dev@gmail.com
        </a>
      </p>
    </div>
  );
};

export default App;
